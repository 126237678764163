<template>
  <div class="roleTab">
    <div class="mainform">
      <div class="form mg-form">
        <div class="el-form">
          <div class="check-num">
            <span>需求数量：</span>
            <span>1000个</span>
          </div>
          <el-table class="table" stripe :data="selectTabData" style="width: 100%" @selection-change="SelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="序号" width="60px">
              <template #default="scope">
                {{scope.$index+1}}
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="orderRowCode" label="采购单编号/行号" width=""></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="sellCode" label="销售订单号" width="150px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="remainNumber" label="未交数量" width="130px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="unit" label="单位" width="80px"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="number" label="送货数量" width="120px">
              <template #default="scope">
                <el-input v-model="scope.row.number" placeholder="请输入"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { request } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  name: 'AddPurchaseCodrBody',
  props: ['tabData', 'dialogData'],
  data () {
    return {
      selectTabData: [],
      newSelectTabData: []
    }
  },
  created () {
    this.selectTabData = this.dialogData.orderData
  },
  mounted () {

  },
  methods: {
    // 获取选中的订单
    SelectionChange (data) {
      this.newSelectTabData = data
      this.newSelectTabData.forEach(item => {
        item.number = ''
      })
    }
  },
  watch: {
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elform.scss';
  .tableNav{
    border: none;
  }
  .roleTab{
    .mg-form {
      padding: 0 10px;
      .el-table th {
        padding: 9px 0;
        background: #ededed;
        font-size: 14px;
      }
      :deep(.el-table__body td){
        padding: 2px 0;
      }
      .el-input__inner{
        height: 32px;
        line-height: 32px;
      }
    }
    .check-num{
      margin-bottom: 10px;
      color: #666;
    }
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
</style>
